import { FC, ReactNode, useMemo, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import styles from './UserLayout.module.less'
import loginBgImg from '../assets/images/loginBgImg.jpg'
import logo from '../assets/images/logo.png'
import { Dropdown, Menu, Space } from 'antd'
import { I18n } from '@/context/I18n'
import IconFont from '@/utils/icon'

const RPath = '/home'

export const UserLayout: FC<{
  children: ReactNode
}> = ({ children }) => {
  const { t } = useTranslation(['translation', 'common'])
  const i18n = I18n.useContainer()
  const imgWrapRef = useRef<any>(null)
  const language = useMemo(() => {
    switch (i18n.language.lang) {
      case 'zh-CN':
        return '简体中文'
      case 'en-US':
        return 'English'
    }
  }, [i18n.language])

  function toggleLang(lang: 'zh-CN' | 'en-US') {
    i18n.changeLanguage(lang)
  }

  return (
    <div className={styles.main}>
      <div
        className={styles.leftWrap}
        ref={imgWrapRef}
        style={{
          width: `${(600 / 789) * imgWrapRef.current?.offsetHeight}px`,
        }}
      >
        <img src={logo} className={styles.logo} alt="logo" />
        <div className={styles.aside}>
          <img src={loginBgImg} className={styles.loginImg} alt="loginImg" />
          <div className={styles.describeWrap}>
            <div className={styles.title}>{t(`${RPath}.智慧通讯服务`)}</div>
            <div className={styles.subtitle}>
              {t(`${RPath}.让每一次用户沟通`)} {t(`${RPath}.更高效更直接`)}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.rightWrap}>
        <div className={styles.titleWrap}>
          <Link to="/home" className={styles.linkWrap}>
            {/* <img src={backIcon} className={styles.backImg} alt="" /> */}
            <div className={styles.text}>{t(`${RPath}.返回官网`)}</div>
          </Link>
          {!!localStorage.getItem('show_lang') && (
            <Dropdown
              placement="bottom"
              arrow
              dropdownRender={() => {
                return (
                  <Menu
                    className={styles.menu}
                    selectable
                    selectedKeys={[i18n.language.lang]}
                    items={[
                      {
                        key: 'zh-CN',
                        onClick: () => toggleLang('zh-CN'),
                        label: '简体中文',
                      },
                      {
                        key: 'en-US',
                        onClick: () => toggleLang('en-US'),
                        label: 'English',
                      },
                    ]}
                  />
                )
              }}
            >
              <div className={styles.linkWrap}>
                <div className={styles.text}>{language}</div>
                <IconFont type="icon-xiala" className={styles.icon}></IconFont>
              </div>
            </Dropdown>
          )}
        </div>
        <div className={styles.childrenWrap}>{children}</div>
      </div>
    </div>
  )
}
