import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // https://github.com/i18next/i18next-http-backend
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      requestOptions: {
        cache: 'no-cache',
      },
    },
    load: 'currentOnly',
    ns: ['common', 'translation'],
    // lng: 'en-US',
    lng: 'zh-CN',
    //选择默认语言，选择内容为上述配置中的key，即en/zh
    fallbackLng: 'zh-CN',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // 不使用 Suspense 处理异常，
    react: { useSuspense: false },
  })

export default i18n
