import { lazy } from 'react'
import NotFoundPage from '../pages/404'
import { Redirect } from 'react-router-dom'

const CardNewList = lazy(
  () =>
    import(
      /* webpackChunkName: "Page" */ '../pages/CardManagement/cardNewList/index'
    )
)

const ResourcesMag = lazy(
  () => import(/* webpackChunkName: "ResourcesMenu" */ '../pages/ResourcesMag')
)

const Material = lazy(() => import('../pages/Material'))

const UserControl = lazy(
  () => import(/* webpackChunkName: "UserControl" */ '../pages/UserControl')
)

const Order = lazy(() => import('../pages/Order'))

const newOrder = lazy(() => import('../pages/Order/new'))

// 数据报表
const DataReport = lazy(() => import('../pages/DataReport'))

const Addressing = lazy(
  () => import(/* webpackChunkName: "Addressing" */ '../pages/addressing')
)

const RoleMag = lazy(() => import('../pages/RoleMag'))

const Permissions = lazy(() => import('../pages/permissions'))
const DomainNameFiling = lazy(() => import('../pages/DomainNameFiling'))
const SubAccountManagement = lazy(() => import('../pages/SubAccountManagement'))
const AuthenticationManagement = lazy(
  () => import('../pages/AuthenticationManagement')
)
const AccountAuthenticationCenter = lazy(
  () => import('../pages/AccountAuthenticatioCenter')
)
const CustomerManagement = lazy(() => import('../pages/CustomerManagement'))
const SignatureManagement = lazy(() => import('../pages/SignatureManagement'))
const SignatureDetails = lazy(
  () => import('../pages/SignatureManagement/SignatureDetails')
)
const SMSManagement = lazy(() => import('../pages/SMSManagement'))
const templateManagement = lazy(() => import('../pages/templateManagement'))
const templateAdd = lazy(
  () => import('../pages/templateManagement/SignatureDetails')
)
const SMSAdd = lazy(() => import('../pages/SMSManagement/SignatureDetails'))

const ShortChain = lazy(() => import('../pages/ShortChain'))
const SMSDetails = lazy(() => import('../pages/SMSManagement/details'))

// 数据回退
const DataBack = lazy(() => import('../pages/DataBack'))

// 集团与公司
const CompanyManagament = lazy(() => import('../pages/CompanyManagement'))
const SubjectManagement = lazy(() => import('../pages/SubjectManagement'))
const SubjectCreate = lazy(() => import('../pages/SubjectManagement/Details'))
const SubjectDetails = lazy(() => import('../pages/SubjectManagement/Details'))
const SubjectEdit = lazy(() => import('../pages/SubjectManagement/Details'))

// 统计
const Statistics = lazy(() => import('../pages/Statistics'))

// 消息模板
const MessageTemplate = lazy(() => import('../pages/MessageTemplate'))

// 消息模板详情
const MessageTemplateDetails = lazy(
  () => import('../pages/MessageTemplate/Details')
)

// 服务号
const ServiceNo = lazy(() => import('../pages/ServiceNo'))

// 服务号详情
const ServiceNoDetails = lazy(() => import('../pages/ServiceNo/Details/index'))

// 服务号签名
const ServiceNoSignature = lazy(() => import('../pages/ServiceNoSignature'))

// 组件库文档
const Doc = lazy(() => import('../pages/doc'))

// 拉参统计
const PullParamsStatistics = lazy(() => import('../pages/PullParamsStatistics'))

// 富媒体数据报表
const MessageDataReport = lazy(() => import('../pages/MessageDataReport'))

// 富媒体发送任务
const sendTasks = lazy(() => import('../pages/SendTasks'))

const sendTasksAdd = lazy(() => import('../pages/SendTasks/Details'))

// 追加任务管理
const SupplementaryTaskManagement = lazy(
  () => import('../pages/SupplementaryTaskManagement')
)

// 异常监控
const AnomalyMonitoring = lazy(() => import('../pages/AnomalyMonitoring'))

// 上行回复
const UplinkReply = lazy(() => import('../pages/UplinkReply'))

// 数据日报
const DailyNewspaper = lazy(() => import('../pages/DailyNewspaper'))

// 观星台
const StarView = lazy(() => import('../pages/StarView'))

// CMPP
const CMPP = lazy(() => import('../pages/CMPP'))

// 广告投放平台
const AdvertisingPlatform = lazy(() => import('../pages/AdvertisingPlatform'))
// 应用
const Applications = lazy(
  () => import('../pages/AdvertisingPlatform/Applications')
)
// 广告主
const Advertiser = lazy(() => import('../pages/AdvertisingPlatform/Advertiser'))
// 广告计划
const AdvertisingPlan = lazy(
  () => import('../pages/AdvertisingPlatform/AdvertisingPlan')
)
// 广告组
const AdvertisingGroup = lazy(
  () => import('../pages/AdvertisingPlatform/AdvertisingGroup')
)
// 广创意
const AdvertisingCreativity = lazy(
  () => import('../pages/AdvertisingPlatform/AdvertisingCreativity')
)

// 创建推广
const AdCreation = lazy(
  () => import('../pages/AdvertisingPlatform/AdCreation/index')
)

// 创建推广2
const AdCreation2 = lazy(
  () => import('../pages/AdvertisingPlatform/AdCreation2/index')
)

// 创建广告创意
const AdCreateIdea = lazy(
  () => import('../pages/AdvertisingPlatform/AdCreateIdea/index')
)

// 任务中心
const AdTaskCenter = lazy(
  () => import('../pages/AdvertisingPlatform/AdTaskCenter/index')
)

// 广告主配置
const ownerList = lazy(
  () => import('@/pages/ConfigurationCenter/OwnerList/index')
)

// 游戏数据报表
// const GameDataReport = lazy(
//   () => import('../pages/GameDataReport/index')
// )

// 游戏报表
const GameReport = lazy(
  () => import('../pages/GameDataReport/GameReport/index')
)

// 实时报表
const RealTimeReport = lazy(
  () => import('../pages/GameDataReport/RealTimeReport/index')
)

// 邮件管理
const MailTaskManagement = lazy(
  () => import('../pages/GameDataReport/MailTaskManagement')
)
// 小游戏报表
const QuickGameReport = lazy(
  () => import('../pages/GameDataReport/QuickGameReport/index')
)
// 小游戏实时报表
const SmallGameRealTimeReport = lazy(
  () => import('../pages/GameDataReport/smallGameRealTimeReport')
)
// 任务列表
const TaskList = lazy(
  () => import('../pages/GameDataReport/MailTaskManagement/TaskList')
)
// 发送记录
const SendRecord = lazy(
  () => import('../pages/GameDataReport/MailTaskManagement/SendRecord')
)
// 任务审核
const TaskReview = lazy(
  () => import('../pages/GameDataReport/MailTaskManagement/TaskReview')
)
// 邮件管理-日报管理列表
const DailyNewspaperMagList = lazy(
  () => import('../pages/MailMag/IAPDailyNewspaper/List')
)
// 邮件管理-日报详情编辑页面
const DailyNewspaperDetailsEdit = lazy(
  () => import('../pages/MailMag/IAPDailyNewspaper/DetailsEditPage')
)
// 邮件管理-IAA日报列表
const IAADailyNewspaperList = lazy(
  () => import('../pages/MailMag/IAADailyNewspaper/List')
)
// 邮件管理-IAA日报编辑详情
const IAADailyNewspaperDetailsEditor = lazy(
  () => import('../pages/MailMag/IAADailyNewspaper/DetailsEditPage')
)

// 邮件管理-小游戏日报列表
const SmallGamesDailyNewspaperList = lazy(
  () => import('../pages/MailMag/SmallGamesNewspaper/List')
)
// 邮件管理-小游戏日报编辑详情
const SmallGamesDailyNewspaperDetailsEditor = lazy(
  () => import('../pages/MailMag/SmallGamesNewspaper/DetailsEditPage')
)

// 邮件管理-发送记录
const DailyNewspaperSendRecord = lazy(
  () => import('../pages/MailMag/SendRecord')
)

// 公共模块
const Commonality = lazy(() => import('../pages/Commonality'))
// 导出记录
const DerivedRecord = lazy(() => import('../pages/Commonality/DerivedRecord'))
// lazada列表
const LazadaList = lazy(() => import('../pages/LazadaData/LazadaList'))
// lazada汇总
const LazadaSummarization = lazy(
  () => import('../pages/LazadaData/LazadaSummarization')
)
// shopee汇总
const ShopeeSummary = lazy(() => import('../pages/LazadaData/ShopeeSummary'))
// 拉取记录
const PullRecords = lazy(() => import('../pages/LazadaData/PullRecords'))

// 数据分析
const DataAnalysis = lazy(() => import('../pages/DataAnalysis/index'))
// 集团维度
const DimensionOfGroup = lazy(
  () => import('../pages/DataAnalysis/DimensionOfGroup')
)
// 公司维度
const DimensionOfCompany = lazy(
  () => import('../pages/DataAnalysis/DimensionOfCompany')
)
// 应用维度
const DimensionOfApplication = lazy(
  () => import('../pages/DataAnalysis/DimensionOfApplication')
)
// 素材中心 - 素材分析
const MaterialAnalysis = lazy(
  () => import('../pages/MaterialCenter/MaterialAnalysis')
)
// 素材中心 - 素材看板
const MaterialKanBanBoard = lazy(
  () => import('../pages/MaterialCenter/MaterialKanBanBoard')
)

// 配置中心 - 预警配置
const Monitor = lazy(() => import('../pages/ConfigurationCenter/Monitor/List'))
// 配置中心 - 预警配置 - 监控的编辑详情
const BalanceMonitoringEditing = lazy(
  () => import('../pages/ConfigurationCenter/Monitor/BalanceMonitoring')
)

// 配置中心 - 人员配置
const Staffing = lazy(() => import('../pages/ConfigurationCenter/Staffing'))
// 配置中心 - 人员配置列表
const StaffingList = lazy(
  () => import('../pages/ConfigurationCenter/Staffing/List')
)
// 配置中心 - 人员配置编辑详情
const StaffingEditingAndDetails = lazy(
  () => import('../pages/ConfigurationCenter/Staffing/EditingAndDetails')
)

// 配置中心 - 应用配置
const AppConfig = lazy(() => import('../pages/ConfigurationCenter/AppConfig'))
// 配置中心 - 快应用配置
const QuickAppConfig = lazy(
  () => import('../pages/ConfigurationCenter/QuickAppConfig')
)

// 广告投放-关联记录
const RelateRecord = lazy(() => import('../pages/RelateRecord/index'))
// 广告投放-修改记录
const ModifyingRecords = lazy(() => import('../pages/ModifyingRecords'))

const routes = [
  {
    path: '/',
    exact: true,
    component: CardNewList,
  },
  {
    path: '/statistics',
    exact: true,
    component: Statistics,
  },
  {
    path: '/userControl',
    component: UserControl,
  },
  {
    path: '/addressing',
    component: Addressing,
  },
  {
    path: '/domainName/filing',
    component: DomainNameFiling,
  },
  //子账号管理
  {
    path: '/sub/account/management',
    component: SubAccountManagement,
  },
  //账号认证
  {
    path: '/authentication/management',
    component: AuthenticationManagement,
  },
  //账号认证详情
  {
    path: '/account/authentication/center',
    component: AccountAuthenticationCenter,
  },
  //签名管理
  {
    path: '/signature/management',
    component: SignatureManagement,
  },
  //签名新增
  {
    path: '/signature/details',
    component: SignatureDetails,
  },
  //模板管理
  {
    path: '/template/management',
    component: templateManagement,
  },
  //模板新增
  {
    path: '/template/add',
    component: templateAdd,
  },
  //短信管理
  {
    path: '/SMS/management',
    component: SMSManagement,
  },
  //短信新增
  {
    path: '/SMS/add',
    component: SMSAdd,
  },
  //短信明细
  {
    path: '/SMS/details',
    component: SMSDetails,
  },
  //客户管理
  {
    path: '/customer/management',
    component: CustomerManagement,
  },
  // {
  //   path: '/page/MaterialCenter',
  //   component: Page,
  //   icon: <CrownOutlined />,
  //   name: 'LowCodeDemo',
  // },
  {
    path: '/material',
    component: Material,
  },
  // 菜单设置
  {
    path: '/resources/mag',
    component: ResourcesMag,
  },
  {
    path: '/order/index',
    component: Order,
  },
  {
    path: '/order/newOrder',
    component: newOrder,
  },
  {
    path: '/dataReport/index',
    component: DataReport,
  },
  {
    path: '/roleMag',
    component: RoleMag,
  },
  {
    path: '/permissions',
    component: Permissions,
  },
  {
    path: '/shortChain/index',
    component: ShortChain,
  },
  {
    path: '/data/back',
    component: DataBack,
  },
  {
    path: '/company', // 公司管理
    component: CompanyManagament,
  },
  {
    path: '/subject',
    exact: true,
    component: SubjectManagement,
  },
  {
    path: '/subject/create',
    exact: true,
    component: SubjectCreate,
  },
  {
    path: '/subject/details',
    exact: true,
    component: SubjectDetails,
  },
  {
    path: '/subject/edit',
    exact: true,
    component: SubjectEdit,
  },
  {
    path: '/message/template',
    exact: true,
    component: MessageTemplate,
  },
  {
    path: '/message/template/add',
    exact: true,
    component: MessageTemplateDetails,
  },
  {
    path: '/message/template/edit',
    exact: true,
    component: MessageTemplateDetails,
  },
  {
    path: '/message/template/details',
    exact: true,
    component: MessageTemplateDetails,
  },
  {
    path: '/service/no',
    exact: true,
    component: ServiceNo,
  },
  {
    path: '/service/no/add',
    exact: true,
    component: ServiceNoDetails,
  },
  {
    path: '/service/no/details',
    exact: true,
    component: ServiceNoDetails,
  },
  {
    path: '/service/number/signature',
    exact: true,
    component: ServiceNoSignature,
  },
  {
    path: '/doc',
    exact: true,
    component: Doc,
  },
  {
    path: '/pull/params/statistics',
    exact: true,
    component: PullParamsStatistics,
  },
  {
    path: '/message/data/report',
    exact: true,
    component: MessageDataReport,
  },
  {
    path: '/send/tasks',
    exact: true,
    component: sendTasks,
  },
  {
    path: '/send/tasks/add',
    exact: true,
    component: sendTasksAdd,
  },
  {
    path: '/supplementary/task/management',
    exact: true,
    component: SupplementaryTaskManagement,
  },
  {
    path: '/anomaly/monitoring',
    exact: true,
    component: AnomalyMonitoring,
  },
  {
    path: '/uplink/reply',
    exact: true,
    component: UplinkReply,
  },
  {
    path: '/daily/newspaper',
    exact: true,
    component: DailyNewspaper,
  },
  {
    path: '/star/view',
    exact: true,
    component: StarView,
  },
  {
    path: '/CMPP',
    exact: true,
    component: CMPP,
  },
  {
    path: '/advertising/platform',
    component: AdvertisingPlatform,
    routes: [
      // 应用
      {
        path: '/advertising/platform',
        exact: true,
        component: Applications,
      },
      {
        // 广告主
        path: '/advertising/platform/advertiser',
        exact: true,
        component: Advertiser,
      },
      {
        // 广告计划
        path: '/advertising/platform/plan',
        exact: true,
        component: AdvertisingPlan,
      },
      {
        // 广告组
        path: '/advertising/platform/group',
        exact: true,
        component: AdvertisingGroup,
      },
      {
        // 广告创意
        path: '/advertising/platform/creativity',
        exact: true,
        component: AdvertisingCreativity,
      },
      {
        // 创建推广老的
        path: '/advertising/platform/promote/create/old',
        exact: true,
        component: AdCreation,
      },
      {
        // 创建推广
        path: '/advertising/platform/promote/create',
        exact: true,
        component: AdCreation2,
      },
      {
        // 创建广告创意
        path: '/advertising/platform/idea/create',
        exact: true,
        component: AdCreateIdea,
      },
      {
        // 任务中心
        path: '/advertising/platform/taskCenter',
        exact: true,
        component: AdTaskCenter,
      },
    ],
  },
  {
    path: '/relate/record',
    exact: true,
    component: RelateRecord,
  },
  {
    path: '/update/record',
    component: ModifyingRecords,
    exact: true,
  },
  {
    path: '/owner/list',
    exact: true,
    component: ownerList,
  },
  // {
  //   path: '/gameDataReport',
  //   exact: true,
  //   component: GameDataReport,
  // },
  {
    path: '/gameDataReport/gameReport',
    exact: true,
    component: GameReport,
  },
  {
    path: '/gameDataReport/RealTimeReport',
    exact: true,
    component: RealTimeReport,
  },
  {
    path: '/gameDataReport/QuickGameReport',
    exact: true,
    component: QuickGameReport,
  },
  {
    path: '/gameDataReport/smallGameRealTimeReport',
    exact: true,
    component: SmallGameRealTimeReport,
  },
  {
    path: '/gameDataReport/MailTask',
    component: MailTaskManagement,
    routes: [
      {
        path: '/gameDataReport/MailTask',
        component: TaskList,
        exact: true,
      },
      {
        path: '/gameDataReport/MailTask/SendRecord',
        component: SendRecord,
        exact: true,
      },
      {
        path: '/gameDataReport/MailTask/TaskReview',
        component: TaskReview,
        exact: true,
      },
    ],
  },
  {
    path: '/mailMag/dailyNewspaperMag',
    component: DailyNewspaperMagList,
    exact: true,
  },
  {
    path: '/mailMag/dailyNewspaperMag/detailsEdit',
    component: DailyNewspaperDetailsEdit,
    exact: true,
  },
  {
    path: '/mailMag/IAADailyNewspaper',
    component: IAADailyNewspaperList,
    exact: true,
  },
  {
    path: '/mailMag/IAADailyNewspaper/detailsEdit',
    component: IAADailyNewspaperDetailsEditor,
    exact: true,
  },
  {
    path: '/mailMag/SmallGamesDailyNewspaper',
    component: SmallGamesDailyNewspaperList,
    exact: true,
  },
  {
    path: '/mailMag/SmallGamesDailyNewspaper/detailsEdit',
    component: SmallGamesDailyNewspaperDetailsEditor,
    exact: true,
  },
  {
    path: '/mailMag/sendRecord',
    component: DailyNewspaperSendRecord,
    exact: true,
  },
  {
    path: '/lazada/list',
    component: LazadaList,
  },
  {
    path: '/lazada/summarization',
    component: LazadaSummarization,
  },
  {
    path: '/lazada/shopeeSummary',
    component: ShopeeSummary,
  },
  {
    path: '/lazada/pullRecords',
    component: PullRecords,
  },
  {
    path: '/commonality',
    component: Commonality,
    routes: [
      {
        path: '/commonality/derivedRecord',
        component: DerivedRecord,
      },
    ],
  },
  {
    path: '/dataAnalysis',
    component: DataAnalysis,
    routes: [
      {
        path: '/dataAnalysis/dimensionOfGroup',
        component: DimensionOfGroup,
        exact: true,
      },
      {
        path: '/dataAnalysis/dimensionOfCompany',
        component: DimensionOfCompany,
        exact: true,
      },
      {
        path: '/dataAnalysis',
        component: DimensionOfApplication,
        exact: true,
      },
    ],
  },
  {
    path: '/materialCenter/materialAnalysis',
    component: MaterialAnalysis,
    exact: true,
  },
  {
    path: '/materialCenter/materialKanBanBoard',
    component: MaterialKanBanBoard,
    exact: true,
  },
  {
    path: '/monitor',
    component: Monitor,
    exact: true,
  },
  {
    path: '/monitor/balanceMonitoring',
    component: BalanceMonitoringEditing,
    exact: true,
  },
  {
    path: '/staffing',
    component: Staffing,
    routes: [
      {
        path: '/staffing',
        component: StaffingList,
        exact: true,
      },
      {
        path: '/staffing/editingAndDetails',
        component: StaffingEditingAndDetails,
        exact: true,
      },
    ],
  },
  {
    path: '/appConfig',
    component: AppConfig,
    exact: true,
  },
  {
    path: '/quickAppConfig',
    component: QuickAppConfig,
    exact: true,
  },
  {
    component: NotFoundPage,
  },
]

export default routes
