import { ReactNode, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Auth } from '../../context/Auth'
import styles from './index.module.less'
import logo from '../../assets/images/logo.png'
import footer from '../../assets/images/home/footer@2x.png'
import zgawab from '../../assets/images/zgawab.jpg'
import { Avatar, Dropdown, Menu } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { I18n } from '@/context/I18n'
import { useTranslation } from 'react-i18next'
import IconFont from '@/utils/icon'

interface IAdvItem {
  id: string
  img: string
  title: string
  desc: string[]
  highlight: string[]
}

const RPath = '/home'

export default function Home() {
  const history = useHistory()
  const auth = Auth.useContainer()
  const { status, user } = auth
  const { t } = useTranslation()
  const i18n = I18n.useContainer()

  const language = useMemo(() => {
    switch (i18n.language.lang) {
      case 'zh-CN':
        return '简体中文'
      case 'en-US':
        return 'English'
    }
  }, [i18n.language])

  function toggleLang(lang: 'zh-CN' | 'en-US') {
    i18n.changeLanguage(lang)
  }

  // 智能短信加分项 模块
  function PlusPoint() {
    const plusList = [
      {
        id: Date.now(),
        img: 'zan@2x',
        title: t(`${RPath}.为品牌加分`),
        desc: t(`${RPath}.企业形象展示,避免被拦截、被误解,提升沟通信任感!`),
      },
      {
        id: Date.now(),
        img: 'cloud@2x',
        title: t(`${RPath}.为注意力加分`),
        desc: t(
          `${RPath}.支持单图文、多图文、轮播卡片等, 信息呈现更直观生动, 让用户一眼被吸引!`
        ),
      },
      {
        id: Date.now(),
        img: 'message@2x',
        title: t(`${RPath}.为信息量加分`),
        desc: t(`${RPath}.在有限空间充分展示更多信息量 , 总有一样能打动用户!`),
      },
      {
        id: Date.now(),
        img: 'open@2x',
        title: t(`${RPath}.为互动转化加分`),
        desc: t(`${RPath}.支持H5、Deeplink和快应用等唤起,让互动转化一触即发!`),
      },
      {
        id: Date.now(),
        img: 'effect@2x',
        title: t(`${RPath}.为效果优化加分`),
        desc: t(`${RPath}.支持数据全链路分析,根据效果不断优化展示方式!`),
      },
      {
        id: Date.now(),
        img: 'more@2x',
        title: t(`${RPath}.查看更多精彩`),
        desc: t(
          `${RPath}.点击此处可查看智慧营销更多精彩,让企业高效提升用户运营效率!`
        ),
      },
    ]

    return (
      <section className={styles.plusPoints}>
        <div className={styles.ppWrappwer}>
          <h3 className={styles.sectionTitle}>
            {t(`${RPath}.智慧营销五大加分项 全面打通内容商业生态`)}
          </h3>
          <div className={styles.ppList}>
            {plusList.length &&
              plusList.map((item, idx) => {
                return (
                  <div
                    className={styles.ppCard}
                    // ...
                    key={item.id + idx}
                    onClick={() =>
                      idx === plusList.length - 1 && history.push('/login')
                    }
                  >
                    {item.title && (
                      <>
                        <div className={styles.ppCardHd}>
                          <img
                            className={styles.ppCardImg}
                            src={
                              require('../../assets/images/home/' +
                                item.img +
                                '.png').default
                            }
                            alt={item.title}
                          />
                          <h3 className={styles.ppCardTitle}>{item.title}</h3>
                        </div>
                        <p className={styles.ppCardDesc}>{item.desc}</p>
                      </>
                    )}
                  </div>
                )
              })}
          </div>
        </div>
      </section>
    )
  }
  // Advanced 模块
  function Advanced() {
    // advanced list 4
    const advList: IAdvItem[] = [
      {
        id: 'adv1',
        img: 'q-connect@2x',
        title: t(`${RPath}.强触达`),
        desc: [
          t(`${RPath}.系统原生入口,无需用户额外安装下载`),
          t(`${RPath}.轻松触达海量活跃用户,一站式覆盖`),
          t(`${RPath}.支持品牌号码认证,及时优化营销链路`),
        ],
        highlight: [
          t(`${RPath}.无需用户额外安装下载`),
          t(`${RPath}.一站式覆盖`),
          t(`${RPath}.品牌号码认证`),
        ],
      },
      {
        id: 'adv2',
        img: 'q-turn@2x',
        title: t(`${RPath}.强转化`),
        desc: [
          t(`${RPath}.内容丰富生动,信息容量更大`),
          t(`${RPath}.点击直达应用、下载、充值等具体场景,搭建完整营销转化闭环`),
        ],
        highlight: [
          t(`${RPath}.信息容量更大`),
          t(`${RPath}.直达应用、下载、充值`),
        ],
      },
      {
        id: 'adv3',
        img: 'q-chain@2x',
        title: t(`${RPath}.强反馈`),
        desc: [
          t(`${RPath}.大数据圈选人群画像,针对性精准投放`),
          t(`${RPath}.完整追跳数据链路,及时优化营销链路`),
        ],
        highlight: [
          t(`${RPath}.针对性精准投放`),
          t(`${RPath}.及时优化营销链路`),
        ],
      },
      {
        id: 'adv4',
        img: 'q-data@2x',
        title: t(`${RPath}.强易用`),
        desc: [
          t(
            `${RPath}.专业团队提供从数据分析、内容策划到效果优化的一站式解决方案`
          ),
          t(`${RPath}.接入快捷操作简易,为企业营销保驾护航`),
        ],
        highlight: [
          t(`${RPath}.数据分析、内容策划到效果优化`),
          t(`${RPath}.接入快捷操作简易`),
        ],
      },
    ]
    // some high light words in advanced section
    const hightLightBlock = (
      content: string,
      keywords: string[]
    ): ReactNode => {
      let arr: string[] = []
      let hithLightCode: string = ''
      keywords.map((item) => {
        if (content.toLowerCase().indexOf(item.toLowerCase()) !== -1) {
          arr = content.split(item)
          hithLightCode = item
        }
        return null
      })
      return (
        <>
          {arr[0]}
          <span style={{ color: '#FC7D01' }}>{hithLightCode}</span>
          {arr[1]}
        </>
      )
    }
    // advanced item in list
    const advItem = function (data: IAdvItem, idx: number): ReactNode {
      return (
        <div key={data.id} className={styles.advItem}>
          <div
            className={styles.advItemContent}
            style={{ float: (idx + 1) % 2 === 0 ? 'right' : 'left' }}
          >
            <img
              className={styles.advImg}
              src={
                require('../../assets/images/home/' + data.img + '.png').default
              }
              alt={data.title}
            />
            <h2 className={styles.advTitle}>{data.title}</h2>
            <ul className={styles.advDesc}>
              {data.desc.length &&
                data.desc.map((item, idx) => {
                  return (
                    <li key={item + idx} className={styles.advLi}>
                      {hightLightBlock(item, data.highlight)}
                    </li>
                  )
                })}
            </ul>
          </div>
        </div>
      )
    }

    return (
      <section className={styles.advanced}>
        <h3 className={styles.sectionTitle}>
          {t(`${RPath}.存量经营时代,企业最直接有效的用户运营方式`)}
        </h3>
        <div
          className={styles.advancedContent}
          style={{
            backgroundImage:
              'url(' +
              (i18n.language.lang === 'zh-CN'
                ? require('../../assets/images/home/circle.png').default
                : require('../../assets/images/home/circle-en.png').default) +
              ')',
          }}
        >
          <div className={styles.advList}>
            {advList.length && advList.map((adv, idx) => advItem(adv, idx))}
          </div>
        </div>
      </section>
    )
  }

  return (
    <div className={styles.container}>
      {/* header signin btn */}
      <header className={styles.header}>
        <img src={logo} className={styles.logo} alt="logo" />
        <div className={styles.btns}>
          {!!localStorage.getItem('show_lang') && (
            <Dropdown
              placement="bottom"
              arrow
              dropdownRender={() => {
                return (
                  <Menu
                    className={styles.menu}
                    selectable
                    selectedKeys={[i18n.language.lang]}
                    items={[
                      {
                        key: 'zh-CN',
                        onClick: () => toggleLang('zh-CN'),
                        label: '简体中文',
                      },
                      {
                        key: 'en-US',
                        onClick: () => toggleLang('en-US'),
                        label: 'English',
                      },
                    ]}
                  />
                )
              }}
            >
              <div className={styles.switchLanguage}>
                <div className={styles.text}>{language}</div>
                <IconFont type="icon-xiala" className={styles.icon}></IconFont>
              </div>
            </Dropdown>
          )}
          {status === 'logged' ? (
            <>
              <Avatar icon={<UserOutlined />} />
              <span className={styles.signinBtn}>{user?.username}</span>
              <span
                className={styles.signupBtn}
                onClick={() => history.push('/')}
              >
                {t(`${RPath}.进入主页`)}
              </span>
            </>
          ) : (
            <>
              <span
                className={styles.signupBtn}
                onClick={() => history.push('/login')}
              >
                {t(`${RPath}.登录`)}
              </span>
            </>
          )}
        </div>
      </header>

      {/* banner */}
      <div
        className={styles.banner}
        style={{
          backgroundImage:
            'url(' +
            require('../../assets/images/home/banner@2x.png').default +
            ')',
        }}
      >
        <div className={styles.bannerCtx}>
          <h1 className={styles.bannerTitle}>
            {t(`${RPath}.助力品牌高效成长`)}
          </h1>
          <p className={styles.bannerDesc}>
            {t(`${RPath}.让存量用户运营更直接、更有效`)}
          </p>
          <div className={styles.bannerBtns}>
            <span
              className={styles.trynow}
              onClick={() => history.push(status === 'logged' ? '/' : '/login')}
            >
              {t(`${RPath}.立即体验`)}
            </span>
          </div>
        </div>
      </div>

      {/* main PlusPoint */}
      <main className={styles.main}>
        <PlusPoint />
        <Advanced />
      </main>

      {/* footer */}
      <footer className={styles.footer}>
        <div className={styles.footerContainer}>
          <p className={styles.ftContent}>
            {t(`${RPath}.立即体验,即可享受智慧通讯服务,开启您的智能之旅!`)}
          </p>
          <div
            className={styles.ftBtn}
            onClick={() => history.push(status === 'logged' ? '/' : '/login')}
          >
            {t(`${RPath}.立即体验`)}
          </div>
        </div>
        <img
          className={styles.footerImg}
          src={footer}
          alt={t(`${RPath}.立即体验,即可享受智慧通讯服务,开启您的智能之旅!`)}
        />

        {/* 备案号 */}
        <div className={styles.footerExtra}>
          <div className={styles.footerExtraContent}>
            <p className={styles.copyright}>
              Copyright © 2022-现在 橙蜂传媒 版权所有 |{' '}
              <a
                target="_blank"
                href="https://beian.miit.gov.cn"
                rel="noreferrer"
              >
                浙ICP备2021039020号
              </a>
            </p>
            <div className={styles.recordCode}>
              <a
                target="_blank"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010902003257"
                rel="noreferrer"
              >
                <img src={zgawab} alt="" />
                <p>浙公网安备 33010902003257号</p>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
