import { useState } from 'react'
import { find } from 'lodash-es'
import { createContainer } from 'unstated-next'
import i18n from '../i18n'

type langTypes = 'zh-CN' | 'en-US'
export interface ILangguage {
  lang: langTypes
  name: string
}

function useI18n() {
  const languageList: ILangguage[] = [
    {
      lang: 'zh-CN',
      name: '中文',
    },
    {
      lang: 'en-US',
      name: '英文',
    },
  ]

  const [language, setLanguage] = useState<ILangguage>({
    lang: 'zh-CN',
    name: '中文',
  })

  const [loading, setLoading] = useState<boolean>(false)

  const changeLanguage = (lang: langTypes) => {
    setLoading(true)
    i18n
      .changeLanguage(lang)
      .then(() => {
        const language = find(languageList, { lang })
        if (!language) return
        setLanguage(language)
        localStorage.setItem('obees_lang', JSON.stringify(language))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    language,
    loading,
    changeLanguage,
  }
}

export const I18n = createContainer(useI18n)

export const CommonPath = 'common'
