import { useState, useEffect, useRef, Suspense, FC, ReactNode } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import ProLayout, { MenuDataItem } from '@ant-design/pro-layout'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { Result, Button, Avatar, Menu, Dropdown } from 'antd'
import { fetchMenu } from '../services/menu'
import { Auth } from '../context/Auth'
import { I18n } from '@/context/I18n'
import styles from './AdminLayout.module.less'
import logo from '@/assets/images/logo.png'
import { useTranslation } from 'react-i18next'
import { iconFontUrl } from '../config'
import IconFont from '@/utils/icon'
import intl from '@/utils/intl'
import classNames from 'classnames'

const RPath = '/home'

const ErrorMessage: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (error.message && /Loading chunk [\d]+ failed/.test(error.message)) {
      // 如果是加载chunk.js文件的报错就自动刷新页面
      window.location.reload()
    }
  }, [])

  return (
    <Result
      status="error"
      title="异常错误"
      subTitle={error.message}
      extra={
        <Button type="primary" onClick={resetErrorBoundary}>
          {t(`${RPath}.重新加载`)}
        </Button>
      }
    />
  )
}

const AvatarDropdown: FC = () => {
  const { t } = useTranslation()
  const auth = Auth.useContainer()
  const i18n = I18n.useContainer()
  const history = useHistory()
  const onLogout = () => {
    auth.signout()
    history.replace('/login')
  }
  const toggleLang = () => {
    if (i18n.language.lang === 'zh-CN') {
      i18n.changeLanguage('en-US')
    } else {
      i18n.changeLanguage('zh-CN')
    }
  }
  return (
    <Dropdown
      placement="bottomRight"
      arrow
      dropdownRender={() => {
        return (
          <Menu
            items={[
              {
                key: 'signout',
                onClick: () => onLogout(),
                icon: <IconFont type="icon-tuichu" />,
                label: t(`${RPath}.退出登录`),
              },
              !!localStorage.getItem('icon-qiehuan')
                ? {
                    key: 'changeLang',
                    onClick: () => toggleLang(),
                    icon: <IconFont type="icon-tuichu" />,
                    label:
                      i18n.language.lang === 'zh-CN'
                        ? '切换英文'
                        : t(`${RPath}.切换中文`),
                  }
                : null,
            ]}
          />
        )
      }}
    >
      <div className={styles.avatarBox}>
        <Avatar src={require('../assets/images/user@3x.png').default} />
        <span className={styles.username}>{auth.user?.username}</span>
      </div>
    </Dropdown>
  )
}

export const AdminLayout: FC<{
  children: ReactNode
  routes: MenuDataItem[]
}> = ({ children, routes }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const [pathname, setPathname] = useState(location.pathname)
  const [isOpen, setIsOpen] = useState(false)
  const actionRef = useRef<{
    reload: () => void
  }>()
  useEffect(() => {
    setPathname(location.pathname)
  }, [location])

  const errorBoundaryonReset = () => {
    window.location.reload()
  }

  return (
    <ProLayout
      siderWidth={intl.value({ 'zh-CN': 195, 'en-US': 280 })}
      headerHeight={58}
      actionRef={actionRef}
      menu={{
        request: async () => {
          const resp = await fetchMenu()
          return resp.data
        },
      }}
      title={false}
      logo={
        <img
          className={classNames([styles.logo, isOpen && styles.isOpenLogo])}
          src={logo}
          alt="logo"
        />
      }
      onCollapse={(isOpen) => {
        setIsOpen(isOpen)
      }}
      navTheme="light"
      location={{
        pathname,
      }}
      fixSiderbar={true}
      fixedHeader={true}
      defaultCollapsed={false}
      breakpoint={false}
      iconfontUrl={iconFontUrl}
      route={{
        path: '/',
        routes,
      }}
      contentStyle={{
        marginBottom: 0,
      }}
      menuItemRender={(item, dom) => {
        return (
          <div
            key={item.path}
            onClick={() => {
              // setPathname(item.path || '/')
              history.push(item.path || '/')
            }}
            className={styles.menuItemStyle}
          >
            {dom}
          </div>
        )
      }}
      rightContentRender={() => <AvatarDropdown />}
    >
      <ErrorBoundary
        FallbackComponent={ErrorMessage}
        onReset={errorBoundaryonReset}
      >
        <Suspense fallback={<div>{t(`${RPath}.加载中`)}...</div>}>
          {children}
        </Suspense>
      </ErrorBoundary>
    </ProLayout>
  )
}
