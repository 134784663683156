import { useHistory } from 'react-router-dom'
import { useCallback, useState } from 'react'
import { Form, Input, Button, Tabs } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { Auth } from '../../../context/Auth'
import styles from '../user.module.less'
import { useTranslation } from 'react-i18next'

const { TabPane } = Tabs

const RPath = '/login'

export default function LoginPage() {
  const history = useHistory()
  const [currTab, setCurrTab] = useState<string>('1')

  const [form] = Form.useForm()

  const auth = Auth.useContainer()

  const { t } = useTranslation()

  const onFinish = useCallback((values: any) => {
    auth
      .signin({
        username: values.username,
        password: values.password,
      })
      .then(() => {
        auth.getUserInfo()
        auth.setIsSignin(true)
      })
      .then(() => {
        // 如果上个页面是企业认证，那么登录后会跳转到企业认证，所以暂时注释了登录成功跳转回上个页面的功能
        // history.replace(from)
        history.replace('/')
      })
  }, [])
  // tab切换回调
  const handleTabChange = (key: string) => {
    setCurrTab(key)
  }

  return (
    <div className={styles.main}>
      <div className={styles.formWrap}>
        <div className={styles.titleWrap}>
          <span className={styles.title}>{t(`${RPath}.欢迎登录`)}</span>
          <span className={styles.subtitle}>
            {t(`${RPath}.蜂行AIM消息投放平台`)}
          </span>
        </div>
        <Form
          form={form}
          name="login"
          className={styles.formContent}
          onFinish={onFinish}
        >
          <Tabs size="large" onTabClick={handleTabChange} activeKey={currTab}>
            <TabPane tab={t(`${RPath}.密码登录`)} key="1">
              <Form.Item
                name="username"
                rules={[
                  { required: true, message: t(`${RPath}.用户名不能为空`) },
                ]}
                className={styles.userName}
              >
                <Input
                  placeholder={t(`${RPath}.请输入您的账号/子账号`)}
                  prefix={<UserOutlined className={styles.itemPrefixIcon} />}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: t(`${RPath}.密码不能为空`) },
                  { min: 8, message: t(`${RPath}.密码长度最少为 8`) },
                ]}
              >
                <Input.Password
                  placeholder={t(`${RPath}.请输入您的密码`)}
                  prefix={<LockOutlined className={styles.itemPrefixIcon} />}
                />
              </Form.Item>
            </TabPane>
          </Tabs>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.fullWidth}
            >
              {t(`${RPath}.登录`)}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
