import ReactDOM from 'react-dom'
// antd样式
import 'antd/dist/antd.less'
import './i18n'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AppProviders } from './AppProviders'
// import { detectZoom } from './utils/index'
// 轮播组件
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
// antd时间组件 中文
import 'moment/locale/zh-cn'

// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as Echarts from 'echarts/core'
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart, LineChart, PieChart } from 'echarts/charts'
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from 'echarts/components'
// 标签自动布局、全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features'
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers'
// 注册必须的组件
Echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  LineChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  PieChart,
])

export let echarts = Echarts

export let zoom = 1

// 处理 windows 笔记本系统默认系统比例为 150% 带来的布局影响
// 只针对 windows 处理，其它系统暂无该情况
// if (navigator.userAgent.toLowerCase().includes('windows')) {
//   const m = detectZoom()
//   zoom = 100 / Number(m)

//   document.body.style.zoom = zoom
//   //设置css变量值
//   document.documentElement.style.setProperty('--zoom', `${zoom}`)
// } else {
document.body.style['zoom'] = 1
//设置css变量值
document.documentElement.style.setProperty('--zoom', `${1}`)
// }

ReactDOM.render(
  // <React.StrictMode>
  <AppProviders>
    <App />
  </AppProviders>,
  // </React.StrictMode>
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
