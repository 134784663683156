import { ILangguage } from '@/context/I18n'

export enum Lang {
  'zh-CN' = 'zh-CN',
  'en-US' = 'en-US',
}

interface Data<T> {
  ['zh-CN']?: T
  ['en-US']?: T
}

// 获取国际化的值
export function getLang(): Lang {
  // 国际化配置
  let lang = localStorage.getItem('obees_lang')
  if (!!lang) {
    let langJson: ILangguage = JSON.parse(lang)
    return langJson.lang as Lang
  } else {
    return Lang['zh-CN']
  }
}

// 根据国际化获取值
function value<T>(data: Data<T>) {
  return data[getLang()]
}

export default {
  value,
}
