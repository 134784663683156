import { useEffect } from 'react'
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import { AdminLayout } from './layouts/AdminLayout'
import { UserLayout } from './layouts/UserLayout'
import adminRoutes from './routes'
import LoginPage from './pages/user/Login'
import { Auth } from './context/Auth'
import { Permission } from './context/Permission'
import Home from './pages/Home'
import '@/assets/styles/common.less'
import '@/global.less'

function AuthenticatedApp() {
  const permission = Permission.useContainer()
  const auth = Auth.useContainer()
  const history = useHistory()
  const location = useLocation()
  useEffect(() => {
    permission.fetchPermissions()
  }, [])

  useEffect(() => {
    if (!auth.user) return
    if (permission.permissions.length !== 0) {
      if (permission.permissions.includes('game:account')) {
        if (location.pathname === '/') history.push('/advertising/platform')
      } else if (
        !auth.user.isCompany &&
        auth.user.fdType !== 1 &&
        !permission.permissions.includes('operation:account')
      ) {
        history.push('/account/authentication/center')
      } else if (
        permission.permissions.includes('card.statistics.data') &&
        auth.isSignin
      ) {
        history.push('/statistics')
        auth.setIsSignin(false)
      }
    }
  }, [auth.user?.isCompany, permission.permissions])

  return (
    <Switch>
      <Route exact path="/home" component={Home} />
      <Route exact path="/login">
        <Redirect to="/" />
      </Route>
      <AdminLayout routes={adminRoutes}>
        {renderRoutes(adminRoutes)}
      </AdminLayout>
    </Switch>
  )
}

function UnauthenticatedApp() {
  const location = useLocation()
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/home" />
      </Route>
      <Route exact path="/home" component={Home} />
      <UserLayout>
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        </Switch>
      </UserLayout>
    </Switch>
  )
}

function App() {
  const auth = Auth.useContainer()

  return auth.status === 'logged' ? (
    <AuthenticatedApp />
  ) : (
    <UnauthenticatedApp />
  )
}
export default App
