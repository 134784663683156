import { DependencyList, EffectCallback, useEffect, useRef } from 'react'
import { request } from '../utils/request'
import { debounce } from 'lodash-es'

let optionalPlatforms: any = []
removeAccountId()

export function getAccountId() {
  return sessionStorage.getItem('accountId')
}

export function setAccountId(data: string) {
  return sessionStorage.setItem('accountId', data)
}

export function removeAccountId() {
  return sessionStorage.removeItem('accountId')
}

export let useOptionalPlatformsEffect = (
  callback: (optionalPlatforms: number[]) => void,
  isOperating: boolean,
  id: string = ''
) => {
  useEffect(() => {
    if (isOperating && !id) return
    if (getAccountId() === id) {
      callback(optionalPlatforms)
      return
    }
    getOptionalPlatforms(id)
    setAccountId(id)
  }, [id])

  function getOptionalPlatforms(id?: string) {
    let params: Record<string, any> = {}
    if (id) params.accountId = id
    request
      .get('/api/thirdSecret/findBySupportPlatform', { params })
      .then((res) => {
        if (res.data && res.data.length > 0) {
          optionalPlatforms = res.data
          callback(Array.from(res.data))
        } else {
          optionalPlatforms = []
          callback([])
        }
      })
      .catch(() => {
        optionalPlatforms = []
        callback([])
      })
  }
}

// 防止首次渲染
export function useDidUpdateEffect(fn: Function, inputs: any[]) {
  const didMountRef = useRef(false)
  useEffect(() => {
    if (didMountRef.current) fn()
    else didMountRef.current = true
  }, inputs)
}

// 只监听首次渲染
export function useFirstUpdateEffect(fn: Function, inputs: any[]) {
  const didMountRef = useRef(true)
  useEffect(() => {
    if (didMountRef.current) fn()
    didMountRef.current = false
  }, inputs)
}

// 只执行一次
export function useCompleteUpdateEffect(fn: Function, inputs: any[]) {
  const onceRef = useRef(false)

  useEffect(() => {
    if (!onceRef.current) fn(toComplete)
  }, inputs)

  function toComplete() {
    onceRef.current = true
  }
}

// 防抖
export function useDebounceEffect(
  effect: EffectCallback,
  deps: DependencyList,
  delay: number
) {
  const timer = useRef<NodeJS.Timeout>()

  useEffect(() => {
    timer.current && clearTimeout(timer.current)
    timer.current = setTimeout(effect, delay)
  }, deps)
}
