import { useState, useEffect, FC, ReactNode } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale/en_US' // 引入antd 语言包
import zhCN from 'antd/lib/locale/zh_CN'
import { Auth } from './context/Auth'
import { Permission } from './context/Permission'
import { I18n } from './context/I18n'
import LangContext from '@/components/context'

import PageLoading from './components/PageLoading'
import { useTranslation } from 'react-i18next'
import { Lang, getLang } from './utils/intl'

const AuthCheck: FC<{ children: ReactNode }> = ({ children }) => {
  const auth = Auth.useContainer()
  const i18n = I18n.useContainer()
  // 确保国际化资源已经加载完成，否则会报错
  const { ready } = useTranslation(['translation', 'common'])
  const [locale, setLocale] = useState(zhCN)

  useEffect(() => {
    auth.getUserInfo()
  }, [])

  useEffect(() => {
    const lang = localStorage.getItem('obees_lang')
      ? JSON.parse(localStorage.getItem('obees_lang') as string)
      : {}
    // const local_lang = window.navigator.language
    // if (local_lang !== lang.lang) {
    //   alert 是否切换本地语言
    // }
    if (lang.lang) {
      i18n.changeLanguage(lang.lang)
    }
  }, [])

  useEffect(() => {
    if (i18n.language.lang === 'zh-CN') {
      setLocale(zhCN)
    } else {
      setLocale(enUS)
    }
  }, [i18n.language])

  if (auth.status === 'init' || !ready) {
    return <PageLoading />
  } else {
    return (
      <ConfigProvider locale={locale} key={i18n.language.lang}>
        <LangContext.Provider value={i18n.language.lang as Lang}>
          {children}
        </LangContext.Provider>
      </ConfigProvider>
    )
  }
}

const AppProviders: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Router>
      <I18n.Provider>
        <Auth.Provider>
          <Permission.Provider>
            <AuthCheck>{children}</AuthCheck>
          </Permission.Provider>
        </Auth.Provider>
      </I18n.Provider>
    </Router>
  )
}

export { AppProviders }
