import { request } from '../utils/request'

export interface loginParams {
  username: string
  password: string
}

export function login(params: loginParams) {
  const localParams = {
    ...params,
    grant_type: 'password',
    scope: 'select',
    client_id: 'client_2',
    client_secret: '123456',
  }
  return request.get('/oauth/token', { params: localParams })
}

export function getProfile() {
  return request.get('/api/account')
}

export function getPermissions() {
  return request.get('/api/permission/currentUser/list')
}

export function getRealSmsAccounts() {
  return request.get('/api/account/getOperationSmsAccount')
}
