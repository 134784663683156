import { Result, Button } from 'antd'
import { useHistory } from 'react-router-dom'

export default function NotFoundPage() {
  const history = useHistory()

  const btnDidClick = () => {
    history.push('/')
  }
  return (
    <Result
      status="404"
      title="404"
      subTitle="抱歉, 您访问的页面不存在"
      extra={
        <Button type="primary" onClick={btnDidClick}>
          返回首页
        </Button>
      }
    />
  )
}
