import axios from 'axios'
import Cookies from 'js-cookie'
import { message, Modal } from 'antd'
import { tokenKey } from '../config'
import { CommonPath, ILangguage } from '../context/I18n'
import i18n from '@/i18n'

export interface IBaseRes<T = never> {
  data: T
  message: string
  code: 0 | 1
}

// 创建一个Map来存储请求和对应的AbortController实例
const requestMap = new Map()
let modal: any = null

export const request = axios.create({ timeout: 1000 * 60 })
const acceptLanguages = {
  'zh-CN': 'zh-CN,zh;q=0.9',
  'en-US': 'en-US,en;q=0.9',
}

// request.requestingCount = 0
// request.allRequestsCompletedEventListenerList = []
// request.prototype.addAllRequestsCompletedEventListener= (fn: Function) => {
//   request.allRequestsCompletedEventListenerList.push(fn)
// }

// request.prototype.removeAllRequestsCompletedEventListener= (fn: Function) => {
//   const index = request.allRequestsCompletedEventListenerList.findIndex(item => item === fn)
//   if (index >= 0) {
//     request.allRequestsCompletedEventListenerList.splice(index, 1)
//   }
// }

// 添加请求拦截器
request.interceptors.request.use(
  (config) => {
    const token = Cookies.get(tokenKey)
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }
    config.headers['Accept-Language'] = acceptLanguages['zh-CN']
    // 国际化配置
    let lang = localStorage.getItem('obees_lang')
    if (!!lang) {
      let langJson: ILangguage = JSON.parse(lang)
      config.headers['Accept-Language'] = acceptLanguages[langJson.lang]
    }
    // 避免接口重复调用
    if (config.disallowRepeatedRequests) {
      if (requestMap.has(config.url)) {
        const signal = requestMap.get(config.url)
        requestMap.delete(config.url)
        if (!signal.aborted) {
          signal.abort()
        }
      }

      // 创建新的AbortController
      const controller = new AbortController()
      config.signal = controller.signal
      requestMap.set(config.url, controller)
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 添加响应拦截器
request.interceptors.response.use(
  (response) => {
    // 避免接口重复调用
    if (
      response?.config.disallowRepeatedRequests &&
      !!response?.config.url &&
      requestMap.has(response?.config.url)
    ) {
      requestMap.delete(response?.config.url)
    }
    if (
      response.data.code !== undefined &&
      response.data.code !== 1 &&
      response.config.verifyCode !== false
    ) {
      message.error(response.data.message || response.data.msg || '请求异常')
      return Promise.reject(response.data)
    }
    return response
  },
  async (error) => {
    if (axios.isCancel(error)) {
      // 完全忽略取消错误
      return {
        data: {
          code: 0,
          data: null,
        },
      }
    }
    // 避免接口重复调用
    if (
      error.response?.config.disallowRepeatedRequests &&
      !!error.response?.config.url &&
      requestMap.has(error.response?.config.url)
    ) {
      requestMap.delete(error.response?.config.url)
    }

    if (
      error?.response?.data?.message === '暂无权限访问' &&
      ['/api/game/app/getTopUrl', '/api/game/app/list'].includes(
        error?.response?.config.url
      )
    ) {
      if (modal === null) {
        modal = Modal.error({
          title: '提示',
          centered: true,
          content: '您没有访问应用配置的权限, 请联系相关人员获权限后再进行操作',
          okText: '确定',
          afterClose() {
            modal = null
          },
        })
      }
      return Promise.reject(error)
    }

    if (
      error.response?.status !== 401 &&
      error.response &&
      error.response.data
    ) {
      if (error.response?.data?.error === 'invalid_grant') {
        message.error('账号或密码有误', 2)
      } else if (error.response?.data?.type === 'application/json') {
        let reader = new FileReader()
        reader.onload = function (event) {
          let jsonData = JSON.parse(event.target!.result as string)
          message.error(jsonData.message, 2)
        }
        reader.readAsText(error.response?.data)
        console.error(error.response?.data)
      } else {
        message.error(error.response.data.message || '请求异常', 2)
      }
    }
    return Promise.reject(error)
  }
)
