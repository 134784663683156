import { request } from '../utils/request'

interface RouteItem {
  path: string
  name: string
  icon?: string
  routes?: RouteItem[]
}

export async function fetchMenu() {
  const resp = await request.get('/api/resource/getMenu')
  resp.data = resp.data.map((item: any) => {
    const routes: RouteItem[] = []
    item.children?.forEach((subitem: any) => {
      routes.push({
        path: subitem.path,
        name: subitem.title,
      })
    })
    const result: RouteItem = {
      path: item.path,
      name: item.title,
      icon: item.icon,
    }
    if (routes.length !== 0) result.routes = routes
    return result
  })
  return resp
}
