import { useState } from 'react'
import { createContainer } from 'unstated-next'
import Cookies from 'js-cookie'
import { login, loginParams, getProfile } from '../services/user'
import { tokenKey } from '../config'
import { removeAccountId } from '@/utils/hooks'

function useAuth() {
  const [status, setStatus] = useState<'init' | 'logged' | 'notLogged'>('init')
  const [user, setUser] = useState<{
    id: string
    mobile: string
    username: string
    roleName: string
    roleId: string
    fdRoleCode: number
    isCompany: boolean
    fdType: number // 0-父账号 1-子账号
    fdCompanyId: string
    fdRealizationMarkArray: number[]
    // 变现标志
    fdRealizationMarkList: number[]
    fdUsername: string
  } | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [isSignin, setIsSignin] = useState(false)

  const getUserInfo = () => {
    setLoading(true)
    return getProfile()
      .then((resp) => {
        if (!resp.data?.id) {
          return Promise.reject()
        }
        setUser({
          id: resp.data.id,
          mobile: resp.data.fdMobile,
          username: resp.data.fdUsername,
          roleName: resp.data.roleName,
          roleId: resp.data.fdRoleId,
          fdRoleCode: resp.data.fdRoleCode,
          isCompany: resp.data.fdIsCompany,
          fdType: resp.data.fdType,
          fdCompanyId: resp.data.fdCompanyId,
          fdRealizationMarkArray: resp.data.fdRealizationMarkArray,
          fdRealizationMarkList: JSON.parse(resp.data.fdRealizationMarkList),
          fdUsername: resp.data.fdUsername,
        })
        setStatus('logged')
      })
      .catch((e) => {
        setStatus('notLogged')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const signin = (params: loginParams) => {
    setLoading(true)
    return login(params)
      .then((resp) => {
        if (!resp.data?.access_token) {
          return Promise.reject()
        }
        Cookies.set(tokenKey, resp.data.access_token)
        // setUser(resp.data)
        setStatus('logged')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const signout = () => {
    Cookies.remove(tokenKey)
    removeAccountId()
    setUser(null)
    setStatus('notLogged')
  }

  return {
    user,
    status,
    loading,
    getUserInfo,
    signin,
    signout,
    isSignin,
    setIsSignin,
  }
}

export const Auth = createContainer(useAuth)
