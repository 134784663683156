import { useState } from 'react'
import { createContainer } from 'unstated-next'
import { getPermissions } from '../services/user'

function usePermission() {
  const [permissions, setPermissions] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const fetchPermissions = () => {
    setLoading(true)
    return getPermissions()
      .then((resp) => {
        setPermissions(resp.data)
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    permissions,
    loading,
    fetchPermissions,
  }
}

export const Permission = createContainer(usePermission)
